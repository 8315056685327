import { Icon, IconProps } from "@chakra-ui/react"

export function IconEngine(props: IconProps) {
  return (
    <Icon viewBox="0 0 840 572" fill="none" {...props}>
      <g transform="translate(0,572) scale(0.1,-0.1)" fill="#000000" stroke="none">
        <path
          d="M3440 5414 c-243 -29 -559 -153 -770 -302 -117 -83 -269 -233 -333
-330 -95 -142 -162 -313 -192 -488 -79 -456 228 -910 800 -1180 184 -86 321
-126 514 -149 129 -15 157 -29 180 -89 18 -46 4 -100 -37 -144 -51 -55 -256
-102 -442 -102 -182 0 -322 36 -510 131 -131 66 -226 91 -348 90 -114 0 -188
-17 -292 -68 -105 -51 -239 -186 -293 -295 -94 -194 -99 -382 -17 -657 126
-421 391 -760 715 -916 202 -98 352 -131 538 -122 134 7 225 31 345 91 408
205 752 748 797 1258 11 118 45 158 134 158 51 0 101 -35 127 -90 44 -90 69
-232 69 -390 -1 -209 -22 -293 -130 -515 -62 -126 -84 -204 -92 -319 -18 -277
172 -550 451 -647 75 -27 92 -29 237 -29 135 0 170 3 252 25 141 36 209 61
335 121 444 211 703 532 778 964 39 224 -21 470 -164 669 -96 135 -269 296
-425 395 -230 146 -570 265 -756 266 -133 1 -196 101 -131 208 27 44 97 75
230 102 90 19 127 21 265 17 194 -6 252 -21 445 -112 180 -86 271 -109 409
-103 79 4 124 11 179 31 160 56 299 182 377 341 85 174 95 338 35 571 -121
469 -407 847 -764 1009 -177 81 -323 111 -505 104 -107 -4 -140 -10 -221 -37
-176 -60 -308 -148 -464 -311 -249 -263 -431 -655 -461 -995 -4 -44 -13 -92
-21 -107 -39 -76 -169 -75 -224 2 -51 72 -97 328 -87 486 11 161 47 283 135
459 100 200 117 367 54 549 -36 104 -84 176 -176 264 -80 76 -195 140 -298
166 -62 16 -193 26 -248 20z m877 -2330 c198 -94 183 -391 -23 -470 -53 -20
-163 -16 -211 10 -174 90 -189 346 -26 447 85 53 167 57 260 13z"
        />
      </g>
    </Icon>
  )
}
