import { Icon, IconProps } from "@chakra-ui/react"

export function IconHome(props: IconProps) {
  return (
    <Icon width="51" height="45" viewBox="0 0 51 45" fill="none" {...props}>
      <path
        d="M49.2609 24.9171L25.6899 1.87012L2.1189 24.9171"
        stroke="white"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M41 41.0002V27.2934L26.0598 13.1831L12 27.2429V41.0002H19V34.0002C19 31.1498 21.1495 29.0002 24 29.0002H28.0124C30.8558 29.0002 33 31.1498 33 34.0002V41.0002H41ZM42 44.0002C39.3453 44.0002 34.6629 44.0002 32 44.0002C30.7938 44.0002 30 43.2041 30 42.0218C30 39.8046 30 36.2293 30 34.0002C30 32.7984 29.1541 32.0002 28.0124 32.0002C26.7976 32.0002 25.2218 32.0002 24 32.0002C22.8389 32.0002 22 32.8157 22 34.0002C22 36.212 22 39.7638 22 41.9854C22 43.1876 21.1958 44.0002 20 44.0002C17.5689 44.0002 13.4879 44.0002 11.036 44.0002C9.8146 44.0002 9 43.2367 9 42.0242C9 37.3495 9 26.0002 9 26.0002C9 26.0002 21.3245 13.6757 24.9861 10.0141C25.6249 9.37533 26.3988 9.37694 27.0778 10.0181C30.9599 13.6845 44 26.0002 44 26.0002C44 26.0002 44 37.2993 44 42.0002C44 43.2376 43.1915 44.0002 42 44.0002Z"
        fill="white"
      />
      <path d="M40 15V3" stroke="white" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
    </Icon>
  )
}
