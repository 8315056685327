import React from "react"
import { Icon, IconProps } from "@chakra-ui/react"

export function IconDownload(props: IconProps) {
  return (
    <Icon width="75px" height="75px" viewBox="0 0 75 75" {...props}>
      <g filter="url(#filter0_d_1847_1762)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.72 34C18.6699 34 19.44 34.7835 19.44 35.75V51.5H55.56V35.75C55.56 34.7835 56.3301 34 57.28 34C58.2299 34 59 34.7835 59 35.75V51.5C59 53.433 57.4599 55 55.56 55H19.44C17.5401 55 16 53.433 16 51.5V35.75C16 34.7835 16.7701 34 17.72 34Z"
          fill="white"
        />
      </g>
      <g filter="url(#filter1_d_1847_1762)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M38 19C39.05 19 39.9011 19.7619 39.9011 20.7017L39.9011 40.1901L46.7545 34.1942C47.497 33.5296 48.7007 33.5296 49.4432 34.1942C50.1856 34.8587 50.1856 35.9362 49.4432 36.6007L39.3443 45.5016C38.6019 46.1661 37.3981 46.1661 36.6557 45.5016L26.5568 36.6007C25.8144 35.9362 25.8144 34.8587 26.5568 34.1942C27.2993 33.5296 28.503 33.5296 29.2455 34.1942L36.0988 40.1901L36.0988 20.7017C36.0988 19.7619 36.95 19 38 19Z"
          fill="white"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_1847_1762"
          x="16"
          y="34"
          width="47"
          height="25"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="2" dy="2" />
          <feGaussianBlur stdDeviation="1" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1847_1762" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1847_1762" result="shape" />
        </filter>
        <filter
          id="filter1_d_1847_1762"
          x="26"
          y="19"
          width="28"
          height="31"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="2" dy="2" />
          <feGaussianBlur stdDeviation="1" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1847_1762" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1847_1762" result="shape" />
        </filter>
      </defs>
    </Icon>
  )
}
