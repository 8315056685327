import React from "react"
import { Icon, IconProps } from "@chakra-ui/react"

export function IconPlay(props: IconProps) {
  return (
    <Icon width="20" height="26" viewBox="0 0 20 26" {...props}>
      <g filter="url(#filter0_d_1688_2549)">
        <path
          d="M8.70578e-07 1.90103C9.05773e-07 1.09585 0.903028 0.620831 1.56653 1.07699L14.8014 10.176C15.3794 10.5733 15.3794 11.4267 14.8014 11.824L1.56653 20.923C0.903027 21.3792 3.99249e-08 20.9041 7.51204e-08 20.099L8.70578e-07 1.90103Z"
          fill="white"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_1688_2549"
          x="0"
          y="0.899292"
          width="19.2349"
          height="24.2014"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="2" dy="2" />
          <feGaussianBlur stdDeviation="1" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1688_2549" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1688_2549" result="shape" />
        </filter>
      </defs>
    </Icon>
  )
}
