import React from "react"
import { Icon, IconProps } from "@chakra-ui/react"

export function IconWindDirection(props: IconProps) {
  return (
    <Icon viewBox="0 0 50 50" {...props}>
      <g filter="url(#filter0_d_31_3229)">
        <path
          d="M18.8361 20.6226C17.191 19.7988 16.3684 20.5343 16.9956 22.2607L24.134 36.1474C24.7636 37.8761 25.7815 37.8749 26.4099 36.1474L33.5506 22.2607C34.179 20.5331 33.3553 19.7988 31.7102 20.6202L25.2732 23.8399L18.8361 20.6226Z"
          fill="white"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_31_3229"
          x="14.7854"
          y="18.2837"
          width="24.9761"
          height="25.1597"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="2" dy="2" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_31_3229" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_31_3229" result="shape" />
        </filter>
      </defs>
    </Icon>
  )
}
