import React from "react"
import { Icon, IconProps } from "@chakra-ui/react"

export function IconCalendar(props: IconProps) {
  return (
    <Icon width="36" height="38" viewBox="0 0 36 38" {...props}>
      <g filter="url(#filter0_d_1680_732)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.27586 0C9.18999 -4.10399e-08 9.93104 0.761116 9.93104 1.7V5.1C9.93104 6.03888 9.18999 6.8 8.27586 6.8C7.36174 6.8 6.62069 6.03888 6.62069 5.1V1.7C6.62069 0.761116 7.36174 4.10399e-08 8.27586 0Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16 0C16.9141 -4.10399e-08 17.6552 0.761116 17.6552 1.7V5.1C17.6552 6.03888 16.9141 6.8 16 6.8C15.0859 6.8 14.3448 6.03888 14.3448 5.1V1.7C14.3448 0.761116 15.0859 4.10399e-08 16 0Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M23.7241 0C24.6383 -4.10399e-08 25.3793 0.761116 25.3793 1.7V5.1C25.3793 6.03888 24.6383 6.8 23.7241 6.8C22.81 6.8 22.069 6.03888 22.069 5.1V1.7C22.069 0.761116 22.81 4.10399e-08 23.7241 0Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.7586 3.4H20.9655V5.95C20.9655 7.35833 22.2006 8.5 23.7241 8.5C25.2477 8.5 26.4828 7.35833 26.4828 5.95V3.4H27.5862C30.0239 3.4 32 5.42964 32 7.93333V29.4667C32 31.9704 30.0239 34 27.5862 34H4.41379C1.97612 34 0 31.9704 0 29.4667V7.93333C0 5.42964 1.97612 3.4 4.41379 3.4H5.51724V5.95C5.51724 7.35833 6.75232 8.5 8.27586 8.5C9.79941 8.5 11.0345 7.35833 11.0345 5.95V3.4H13.2414L13.2414 5.95C13.2414 7.35833 14.4765 8.5 16 8.5C17.5235 8.5 18.7586 7.35833 18.7586 5.95V3.4ZM29.5385 29.2148V11H2.46154V29.2148C2.46154 30.4667 3.4496 31.4815 4.66843 31.4815H27.3316C28.5504 31.4815 29.5385 30.4667 29.5385 29.2148Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M27 16.5C27 17.3284 26.2389 18 25.3 18H6.7C5.76112 18 5 17.3284 5 16.5C5 15.6716 5.76112 15 6.7 15L25.3 15C26.2389 15 27 15.6716 27 16.5Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M27 23.5C27 24.3284 26.2389 25 25.3 25H6.7C5.76112 25 5 24.3284 5 23.5C5 22.6716 5.76112 22 6.7 22H25.3C26.2389 22 27 22.6716 27 23.5Z"
          fill="white"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_1680_732"
          x="0"
          y="0"
          width="36"
          height="38"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="2" dy="2" />
          <feGaussianBlur stdDeviation="1" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1680_732" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1680_732" result="shape" />
        </filter>
      </defs>
    </Icon>
  )
}
