import React from "react"
import { Icon, IconProps } from "@chakra-ui/react"

export function IconMonitor(props: IconProps) {
  return (
    <Icon width="60" height="54" viewBox="0 0 60 54" {...props}>
      <g filter="url(#filter0_d_1680_1407)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6 0C2.68629 0 0 2.68629 0 6V34C0 37.3137 2.68629 40 6 40H50C53.3137 40 56 37.3137 56 34V6C56 2.68629 53.3137 0 50 0H6ZM6 4C4.89543 4 4 4.89543 4 6V34C4 35.1046 4.89543 36 6 36H50C51.1046 36 52 35.1046 52 34V6C52 4.89543 51.1046 4 50 4H6Z"
          fill="white"
        />
        <path
          d="M23.5789 40H32.4211L38.7843 48.396C39.2835 49.0546 38.8137 50 37.9874 50H18.0126C17.1863 50 16.7165 49.0546 17.2157 48.396L23.5789 40Z"
          fill="white"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_1680_1407"
          x="0"
          y="0"
          width="60"
          height="54"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="2" dy="2" />
          <feGaussianBlur stdDeviation="1" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1680_1407" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1680_1407" result="shape" />
        </filter>
      </defs>
    </Icon>
  )
}
