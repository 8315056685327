import React from "react"
import { Icon, IconProps } from "@chakra-ui/react"

export function IconCameraOutline(props: IconProps) {
  return (
    <Icon viewBox="0 0 100 100" {...props}>
      <g filter="url(#filter0_d_382_9599)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M63.9539 27.7002L65.1432 29H66.905H78C79.6409 29 81 30.3591 81 32V74C81 75.6409 79.6409 77 78 77H22C20.3591 77 19 75.6409 19 74V32C19 30.3591 20.3591 29 22 29H33.095H34.8568L36.0461 27.7002L41.2618 22H58.7382L63.9539 27.7002ZM60.5 18H39.5L33.095 25H22C18.15 25 15 28.15 15 32V74C15 77.85 18.15 81 22 81H78C81.85 81 85 77.85 85 74V32C85 28.15 81.85 25 78 25H66.905L60.5 18ZM65.75 61.75L57 53H64C64 45.265 57.735 39 50 39C47.97 39 46.045 39.455 44.33 40.225L41.74 37.67C44.225 36.34 46.99 35.5 50 35.5C59.66 35.5 67.5 43.34 67.5 53H74.5L65.75 61.75ZM50 70.5C40.34 70.5 32.5 62.66 32.5 53H25.5L34.25 44.25L43 53H36C36 60.735 42.265 67 50 67C52.03 67 53.955 66.545 55.67 65.775L58.26 68.365C55.775 69.66 53.01 70.5 50 70.5Z"
          fill="white"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_382_9599"
          x="7"
          y="18"
          width="78"
          height="71"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="-4" dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_382_9599" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_382_9599" result="shape" />
        </filter>
      </defs>
    </Icon>
  )
}
