import { Icon, IconProps } from "@chakra-ui/react"

export const UpArrowIcon = (props: IconProps) => {
  return (
    <Icon viewBox="0 0 46 46" fill="none" {...props}>
      <g filter="url(#up_arrow_icon)">
        <path
          d="M25.999 15.27v24.665c0 1.693.844 3.065 2.501 3.065s2.5-1.372 2.5-3.065V6.778c0-2.768-2.25-3.653-4.05-1.807L14.877 17.868a3.25 3.25 0 0 0 .006 4.446l.157.166c1.255 1.322 3.341 1.292 4.558-.066l6.401-7.143z"
          fill="#fff"
        />
      </g>
      <defs>
        <filter
          id="up_arrow_icon"
          x="12"
          y="4"
          width="21"
          height="43"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="up_arrow_icon" />
          <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="B" />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="1" />
          <feComposite in2="B" operator="out" />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.75 0" />
          <feBlend in2="up_arrow_icon" />
          <feBlend in="SourceGraphic" />
        </filter>
      </defs>
    </Icon>
  )
}
