import { useDarkMode } from "@/hooks/useDarkMode"
import { Box } from "@chakra-ui/react"

export const BaseTemplate = ({
  isAutoThemeMode,
  children,
}: {
  isAutoThemeMode?: boolean
  children: React.ReactNode
}) => {
  const { isDark } = useDarkMode()

  return (
    <Box
      className="background-pattern"
      position="relative"
      display="flex"
      justifyContent="center"
      alignItems="center"
      minWidth="1920px"
      minHeight="1080px"
      width="100%"
      height="100vh"
      backgroundColor={isAutoThemeMode ? (isDark ? "#14181b" : "#5b5b5b") : "#14181b"}
      backgroundSize="1920px 1080px"
      backgroundRepeat="no-repeat"
      backgroundPosition="center"
    >
      <Box width="1920px" height="1080px" overflow="hidden" position="relative" boxShadow="0px 0px 50px 1px #ffffff68">
        {children}
      </Box>
    </Box>
  )
}
