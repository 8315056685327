import { Icon, IconProps } from "@chakra-ui/react"

export const LeftArrowIcon = (props: IconProps) => {
  return (
    <Icon viewBox="0 0 46 46" fill="none" {...props}>
      <g filter="url(#left_arrow_icon)">
        <path
          d="M15.27 25.999h24.665c1.693 0 3.065.844 3.065 2.501S41.628 31 39.935 31H6.778c-2.768 0-3.653-2.25-1.807-4.05l12.896-12.073a3.25 3.25 0 0 1 4.446.006l.166.157c1.322 1.255 1.292 3.341-.066 4.558l-7.143 6.401z"
          fill="#fff"
        />
      </g>
      <defs>
        <filter
          id="left_arrow_icon"
          x="2"
          y="14"
          width="43"
          height="21"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="left_arrow_icon" />
          <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="B" />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="1" />
          <feComposite in2="B" operator="out" />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.75 0" />
          <feBlend in2="left_arrow_icon" />
          <feBlend in="SourceGraphic" />
        </filter>
      </defs>
    </Icon>
  )
}
