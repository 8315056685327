import { IconProps, Icon } from "@chakra-ui/icon"

export const IconAroundVision = (props: IconProps) => {
  return (
    <Icon width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
      <path d="M11.0833 6.50004V2.83337H12.9166V6.50004H11.0833Z" fill="#ffffff" />
      <path d="M2.83325 12.9167H6.49992L6.49992 11.0834H2.83325V12.9167Z" fill="#ffffff" />
      <path d="M21.1666 12.9167H17.4999V11.0834H21.1666V12.9167Z" fill="#ffffff" />
      <path d="M11.0833 21.1667V17.5H12.9166V21.1667H11.0833Z" fill="#ffffff" />
      <path
        d="M13.5115 7.59116L13.478 7.62484L5.01257 16.0902L7.87716 18.9548L16.3567 10.4753L16.3762 10.4559L16.3957 10.4364C16.4026 10.4294 16.4096 10.4224 16.4166 10.4155C16.4269 10.4052 16.4373 10.395 16.4474 10.3846C17.9617 8.83792 18.824 6.90209 19.0256 4.94175C17.0653 5.14339 15.1295 6.00568 13.5829 7.51999C13.5756 7.52714 13.5684 7.53436 13.5612 7.54159C13.5559 7.54693 13.5506 7.55227 13.5453 7.55759L13.5115 7.59116Z"
        fill="#ffffff"
      />
      <circle cx="12" cy="12" r="11.25" stroke="#ffffff" strokeWidth="1.5" />
    </Icon>
  )
}
