import { useColorMode } from "@chakra-ui/react"

export function useDarkMode() {
  const { colorMode = "light", toggleColorMode, setColorMode } = useColorMode()
  const isDark = colorMode === "dark"

  return {
    isDark,
    colorMode,
    toggleThemeMode: toggleColorMode,
    setThemeMode: setColorMode,
  }
}
