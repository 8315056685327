import { Icon, IconProps } from "@chakra-ui/react"

export function IconCameraFill(props: IconProps) {
  return (
    <Icon viewBox="0 0 100 100" {...props}>
      <g filter="url(#filter0_d_382_9573)">
        <path
          d="M78 26H66.905L60.5 19H39.5L33.095 26H22C18.15 26 15 29.15 15 33V75C15 78.85 18.15 82 22 82H78C81.85 82 85 78.85 85 75V33C85 29.15 81.85 26 78 26ZM50 71.5C40.34 71.5 32.5 63.66 32.5 54H25.5L34.25 45.25L43 54H36C36 61.735 42.265 68 50 68C52.03 68 53.955 67.545 55.67 66.775L58.26 69.365C55.775 70.66 53.01 71.5 50 71.5ZM65.75 62.75L57 54H64C64 46.265 57.735 40 50 40C47.97 40 46.045 40.455 44.33 41.225L41.74 38.67C44.225 37.34 46.99 36.5 50 36.5C59.66 36.5 67.5 44.34 67.5 54H74.5L65.75 62.75Z"
          fill="white"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_382_9573"
          x="7"
          y="19"
          width="78"
          height="71"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="-4" dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_382_9573" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_382_9573" result="shape" />
        </filter>
      </defs>
    </Icon>
  )
}
