import { Icon, IconProps } from "@chakra-ui/react"

export const RightArrowIcon = (props: IconProps) => {
  return (
    <Icon viewBox="0 0 46 46" fill="none" {...props}>
      <g filter="url(#right_arrow_icon)">
        <path
          d="M30.73 25.999H6.065C4.372 25.999 3 26.843 3 28.5S4.372 31 6.065 31h33.157c2.768 0 3.653-2.25 1.807-4.05L28.133 14.877a3.25 3.25 0 0 0-4.446.006l-.166.157c-1.322 1.255-1.292 3.341.066 4.558l7.143 6.401z"
          fill="#fff"
        />
      </g>
      <defs>
        <filter
          id="right_arrow_icon"
          x="1"
          y="14"
          width="43"
          height="21"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="right_arrow_icon" />
          <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="B" />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="1" />
          <feComposite in2="B" operator="out" />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.75 0" />
          <feBlend in2="right_arrow_icon" />
          <feBlend in="SourceGraphic" />
        </filter>
      </defs>
    </Icon>
  )
}
