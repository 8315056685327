import React from "react"
import { Icon, IconProps } from "@chakra-ui/react"

export function IconSuccess(props: IconProps) {
  return (
    <Icon width="80" height="80" viewBox="0 0 80 80" {...props}>
      <circle cx="40" cy="40" r="38" stroke="#8EC873" strokeWidth="4" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M64.13 20.8898C65.29 22.0762 65.29 23.9997 64.13 25.1861L30.9617 59.1102C29.8017 60.2966 27.921 60.2966 26.7611 59.1102L15.87 47.971C14.71 46.7846 14.71 44.861 15.87 43.6746C17.03 42.4882 18.9106 42.4882 20.0706 43.6746L28.8614 52.6657L59.9294 20.8898C61.0894 19.7034 62.9701 19.7034 64.13 20.8898Z"
        fill="#8EC873"
      />
    </Icon>
  )
}
