import React from "react"
import { Icon, IconProps } from "@chakra-ui/react"

export function IconSun(props: IconProps) {
  return (
    <Icon viewBox="0 0 100 100" {...props}>
      <g filter="url(#filter0_d_369_6218)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M53 20.5062V24.1368V24.5062H51H50H49H47V24.1368V20.5062V16.7284C47 15 48 14 50 14C52 14 53 15 53 16.7284V20.5062ZM53 28.1696C53.6207 28.2558 54.2329 28.3684 54.8356 28.5062C63.7435 30.5429 70.5535 38.0858 71.4938 47.3632C71.5673 48.0881 71.6049 48.8236 71.6049 49.5679C71.6049 50.3122 71.5673 51.0477 71.4938 51.7726C70.3894 62.6691 61.1878 71.1728 50 71.1728C38.8122 71.1728 29.6106 62.6691 28.5062 51.7726C28.4327 51.0477 28.3951 50.3122 28.3951 49.5679C28.3951 48.8236 28.4327 48.0881 28.5062 47.3632C29.4465 38.0858 36.2565 30.5429 45.1644 28.5062C45.7671 28.3684 46.3793 28.2558 47 28.1696C47.9805 28.0334 48.982 27.963 50 27.963C51.018 27.963 52.0195 28.0334 53 28.1696ZM75.4938 49V47.1667V47H79.4938H83.2716C85 47 86 48 86 50C86 52 85 53 83.2716 53H79.4938H75.4938V51.9691V51V49.5679V49ZM24.5062 51V51.9691V53H20.5062H16.7284C15 53 14 52 14 50C14 48 15 47 16.7284 47H20.5062H24.5062V47.1667V49V49.5679V51ZM67.6049 49.5679C67.6049 59.2908 59.7229 67.1728 50 67.1728C40.2771 67.1728 32.3951 59.2908 32.3951 49.5679C32.3951 39.845 40.2771 31.963 50 31.963C59.7229 31.963 67.6049 39.845 67.6049 49.5679ZM47 83.2778V79.5V75.5H49H51H53V79.5V83.2778C53 85.0062 52 86.0062 50 86.0062C48 86.0062 47 85.0062 47 83.2778ZM71.4053 24.3522L65.9055 29.8519L70.1482 34.0945L75.6479 28.5948C76.8701 27.3727 76.8701 25.9584 75.4558 24.5442C74.0416 23.13 72.6274 23.13 71.4053 24.3522ZM65.9055 70.1482L70.1482 65.9056L75.6479 71.4053C76.8701 72.6275 76.8701 74.0417 75.4558 75.4559C74.0416 76.8701 72.6274 76.8701 71.4053 75.648L65.9055 70.1482ZM34.0945 70.1482L28.5947 75.648C27.3726 76.8701 25.9584 76.8701 24.5442 75.4559C23.1299 74.0417 23.1299 72.6275 24.3521 71.4053L29.8518 65.9056L34.0945 70.1482ZM34.0945 29.8519L29.8518 34.0945L24.3521 28.5948C23.1299 27.3727 23.1299 25.9584 24.5442 24.5442C25.9584 23.13 27.3726 23.13 28.5947 24.3522L34.0945 29.8519Z"
          fill="white"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_369_6218"
          x="6"
          y="14"
          width="80"
          height="80.0062"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="-4" dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_369_6218" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_369_6218" result="shape" />
        </filter>
      </defs>
    </Icon>
  )
}
