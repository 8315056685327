import React from "react"
import { Icon, IconProps } from "@chakra-ui/react"

export function IconFolderOpen(props: IconProps) {
  return (
    <Icon width="100px" height="75px" viewBox="0 0 100 75" {...props}>
      <g filter="url(#filter0_d_1353_16282)">
        <path
          d="M58.1345 19C56.5546 19 55.1539 20.0136 54.663 21.5119L54.2257 22.8468L40.5705 22.8468C38.9145 22.8468 37.4657 23.9585 37.0406 25.5555L36.1244 28.9973C36.0345 29.3349 35.9951 29.6714 36.0006 30H39.6603L40.5705 26.491H54.2257C55.8055 26.491 57.2063 25.4775 57.6972 23.9791L58.1345 22.6443H73.3458L66.6788 48.0942L67.874 52.4751C68.3632 54.2681 67.3799 56.0335 65.7614 56.7105C66.7166 57.2753 68.0852 56.9113 68.4379 55.5736L76.8778 23.5717C77.4872 21.2609 75.7405 19 73.3458 19H58.1345Z"
          fill="white"
        />
      </g>
      <g filter="url(#filter1_d_1353_16282)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M22.126 34.5249C21.5027 32.2401 23.2703 30 25.6966 30H58.9014C60.5764 30 62.0417 31.0979 62.472 32.6751L67.874 52.4751C68.4973 54.7599 66.7297 57 64.3034 57H31.0986C29.4236 57 27.9583 55.9021 27.5279 54.3249L22.126 34.5249ZM58.9014 33.6H25.6966L31.0986 53.4H64.3034L58.9014 33.6Z"
          fill="white"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_1353_16282"
          x="36.0001"
          y="19"
          width="44.9999"
          height="41.9668"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="2" dy="2" />
          <feGaussianBlur stdDeviation="1" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1353_16282" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1353_16282" result="shape" />
        </filter>
        <filter
          id="filter1_d_1353_16282"
          x="22"
          y="30"
          width="50"
          height="31"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="2" dy="2" />
          <feGaussianBlur stdDeviation="1" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1353_16282" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1353_16282" result="shape" />
        </filter>
      </defs>
    </Icon>
  )
}
