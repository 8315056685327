import React from "react"
import { Icon, IconProps } from "@chakra-ui/react"

export function IconDoubleArrow(props: IconProps) {
  return (
    <Icon viewBox="0 0 48 58" {...props}>
      <g filter="url(#filter0_bd_51_1068)">
        <path
          d="M16.1151 5.05441C15.1474 4.09653 15.1474 2.54349 16.1151 1.58561L16.9912 0.718412C17.9589 -0.23947 19.5279 -0.239471 20.4956 0.718411L43.2742 23.2656C44.2419 24.2235 44.2419 25.7765 43.2742 26.7344L20.4956 49.2816C19.5279 50.2395 17.9589 50.2395 16.9912 49.2816L16.1151 48.4144C15.1474 47.4565 15.1474 45.9035 16.1151 44.9456L36.2476 25L16.1151 5.05441Z"
          fill="white"
          fillOpacity="0.65"
          shapeRendering="auto"
        />
        <path
          d="M5.60189 11.992C6.5696 11.0341 8.13858 11.0341 9.10629 11.992L22.2478 25L9.1063 38.008C8.13858 38.9659 6.5696 38.9659 5.60189 38.008L4.72579 37.1408C3.75808 36.1829 3.75808 34.6299 4.72579 33.672L13.4868 25L4.72578 16.328C3.75807 15.3701 3.75807 13.8171 4.72578 12.8592L5.60189 11.992Z"
          fill="white"
          fillOpacity="0.65"
          shapeRendering="autos"
        />
      </g>
      <defs>
        <filter
          id="filter0_bd_51_1068"
          x="-11"
          y="-15"
          width="70"
          height="80"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImage" stdDeviation="7.5" />
          <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_51_1068" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0" />
          <feBlend mode="normal" in2="effect1_backgroundBlur_51_1068" result="effect2_dropShadow_51_1068" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_51_1068" result="shape" />
        </filter>
      </defs>
    </Icon>
  )
}
