import { Box, Icon, BoxProps } from "@chakra-ui/react"

interface IconIndicatorSetProps {
  indicatorSize: string
}

export function IconIndicatorSet({ indicatorSize, ...props }: IconIndicatorSetProps & BoxProps) {
  return (
    <Box
      maxW={indicatorSize ? indicatorSize : "502px"}
      overflow="hidden"
      display="flex"
      alignItems="center"
      flexDir="column"
      {...props}
    >
      <Icon viewBox="0 0 512 22" w="512px">
        <g filter="url(#filter0_d_18_1017)">
          <path fillRule="evenodd" clipRule="evenodd" d="M254 12L254 2L258 2L258 12L254 12Z" fill="white" />
        </g>
        <g filter="url(#filter1_d_18_1017)">
          <path fillRule="evenodd" clipRule="evenodd" d="M510 16H6V12H510V16Z" fill="white" />
        </g>
        <defs>
          <filter
            id="filter0_d_18_1017"
            x="250"
            y="0"
            width="12"
            height="18"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="-2" dy="2" />
            <feGaussianBlur stdDeviation="2" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_18_1017" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_18_1017" result="shape" />
          </filter>
          <filter
            id="filter1_d_18_1017"
            x="0"
            y="10"
            width="512"
            height="12"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="-2" dy="2" />
            <feGaussianBlur stdDeviation="2" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_18_1017" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_18_1017" result="shape" />
          </filter>
        </defs>
      </Icon>
    </Box>
  )
}
