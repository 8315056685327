import { Icon, IconProps } from "@chakra-ui/react"

export function IconStorage(props: IconProps) {
  return (
    <Icon width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
      <g clipPath="url(#clip0_3206_19752)">
        <g filter="url(#filter0_d_3206_19752)">
          <path
            d="M13 2.04999V5.07999C16.39 5.56999 19 8.46999 19 12C19 12.9 18.82 13.75 18.52 14.54L21.12 16.07C21.68 14.83 22 13.45 22 12C22 6.81999 18.05 2.54999 13 2.04999V2.04999ZM12 19C8.13 19 5 15.87 5 12C5 8.46999 7.61 5.56999 11 5.07999V2.04999C5.94 2.54999 2 6.80999 2 12C2 17.52 6.47 22 11.99 22C15.3 22 18.23 20.39 20.05 17.91L17.45 16.38C16.7976 17.199 15.9686 17.8601 15.0249 18.3138C14.0812 18.7675 13.0471 19.002 12 19V19Z"
            fill="white"
          />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_d_3206_19752"
          x="1"
          y="1.04999"
          width="24"
          height="23.95"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="1" dy="1" />
          <feGaussianBlur stdDeviation="1" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3206_19752" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3206_19752" result="shape" />
        </filter>
        <clipPath id="clip0_3206_19752">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </Icon>
  )
}
