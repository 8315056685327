import { Icon, IconProps } from "@chakra-ui/react"

interface IconVesselProps extends IconProps {
  isDark: boolean
}

export function IconVessel({ isDark, ...props }: IconVesselProps) {
  return (
    <Icon width="116" height="649" viewBox="0 0 116 649" fill="none" {...props} pos="relative">
      <path
        d="M114.461 629.548L99.3524 646.349C97.8352 648.036 95.6729 649 93.4039 649H22.5961C20.3271 649 18.1648 648.036 16.6476 646.349L1.53861 629.548C0.548043 628.446 0 627.017 0 625.536V103.8C0 56.4348 47.125 0 58 0C68.875 0 116 56.4348 116 103.8V625.536C116 627.017 115.452 628.446 114.461 629.548Z"
        fill={isDark ? "#343434" : "#9C9C9C"}
      />
      <path
        d="M105 111.467V611C105 614.314 102.314 617 99 617H17C13.6863 617 11 614.314 11 611V111.467C11 69.8355 48.1707 30 58 30C67.8293 30 105 69.8355 105 111.467Z"
        fill={isDark ? "white" : "black"}
        fillOpacity="0.2"
      />
      <rect x="20" y="115" width="76" height="465" rx="8" fill={isDark ? "black" : "white"} fillOpacity="0.5" />
      <rect x="26" y="498" width="64" height="64" rx="8" fill={isDark ? "white" : "black"} fillOpacity="0.2" />
      <rect x="24" y="191" width="68" height="25" rx="8" fill={isDark ? "white" : "black"} fillOpacity="0.2" />
      <rect x="24" y="437" width="68" height="25" rx="8" fill={isDark ? "white" : "black"} fillOpacity="0.2" />
    </Icon>
  )
}
