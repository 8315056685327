import React from "react"
import { Icon, IconProps } from "@chakra-ui/react"

export function IconMoon(props: IconProps) {
  return (
    <Icon viewBox="0 0 100 100" {...props}>
      <g filter="url(#filter0_d_369_6219)">
        <path
          d="M27.0204 19.9217C31.189 18.0575 35.8115 17 40.6667 17C59.084 17 74 31.8024 74 50C74 68.1976 59.084 83 40.6667 83C35.8115 83 31.189 81.9425 27.0204 80.0783C36.4861 73.3777 42.6667 62.4251 42.6667 50C42.6667 37.5749 36.4861 26.6223 27.0204 19.9217Z"
          stroke="white"
          strokeWidth="4"
          shapeRendering="auto"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_369_6219"
          x="15"
          y="15"
          width="61"
          height="78"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="-4" dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_369_6219" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_369_6219" result="shape" />
        </filter>
      </defs>
    </Icon>
  )
}
