import React from "react"
import { Icon, IconProps } from "@chakra-ui/react"

export const IconROTBackground = (props: IconProps) => {
  return (
    <Icon viewBox="0 0 272 83" {...props}>
      <g filter="url(#filter0_d_1139_17922)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M57 75H4V0H268V75H215L176.631 41.8635C172.999 38.7262 168.359 37 163.559 37H108.441C103.641 37 99.0012 38.7262 95.3685 41.8635L57 75Z"
          fill="white"
          fillOpacity="0.1"
          shapeRendering="auto"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_1139_17922"
          x="0"
          y="0"
          width="272"
          height="83"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1139_17922" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1139_17922" result="shape" />
        </filter>
      </defs>
    </Icon>
  )
}
