import React from "react"
import { Icon, IconProps } from "@chakra-ui/react"

export function IconFail(props: IconProps) {
  return (
    <Icon width="80" height="80" viewBox="0 0 80 80" {...props}>
      <circle cx="40" cy="40" r="38" stroke="#DB514A" strokeWidth="4" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.6276 20.6276C21.4645 19.7908 22.8212 19.7908 23.6581 20.6276L40 36.9695L56.3419 20.6276C57.1788 19.7908 58.5355 19.7908 59.3724 20.6276C60.2092 21.4645 60.2092 22.8212 59.3724 23.6581L43.0305 40L59.3724 56.3419C60.2092 57.1787 60.2092 58.5355 59.3724 59.3723C58.5355 60.2091 57.1788 60.2091 56.3419 59.3723L40 43.0304L23.6581 59.3724C22.8212 60.2092 21.4645 60.2092 20.6276 59.3724C19.7908 58.5355 19.7908 57.1788 20.6276 56.3419L36.9696 40L20.6276 23.6581C19.7908 22.8213 19.7908 21.4645 20.6276 20.6276Z"
        fill="#DB514A"
      />
    </Icon>
  )
}
