import React from "react"
import { Icon, IconProps } from "@chakra-ui/react"

export function IconDenied(props: IconProps) {
  return (
    <Icon width="80" height="80" viewBox="0 0 80 80" {...props}>
      <circle cx="40" cy="40" r="38" stroke="#F0CA7C" strokeWidth="4" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.6034 40C12.6034 38.8165 13.5628 37.8571 14.7463 37.8571L37.8572 37.8571L65.2539 37.8571C66.4373 37.8571 67.3967 38.8165 67.3967 40C67.3967 41.1834 66.4373 42.1428 65.2539 42.1428L37.8572 42.1428L14.7463 42.1429C13.5628 42.1429 12.6034 41.1835 12.6034 40Z"
        fill="#F0CA7C"
      />
    </Icon>
  )
}
