import React from "react"
import { Icon, IconProps } from "@chakra-ui/react"

export function IconFolder(props: IconProps) {
  return (
    <Icon width="100" height="75" fill="none" viewBox="0 0 100 75" {...props}>
      <g filter="url(#filter0_d_1384_16296)">
        <path
          d="M51.247 16C49.7205 16 48.3272 16.8687 47.6553 18.2394L45.9752 21.6667H32C29.7909 21.6667 28 23.4575 28 25.6667V33C28 30.7909 29.7909 29 32 29V25.6667L45.9752 25.6667C47.5017 25.6667 48.8951 24.7979 49.5669 23.4273L51.247 20L68 20V29C70.2091 29 72 30.7909 72 33V20C72 17.7909 70.2091 16 68 16H51.247Z"
          fill="white"
        />
      </g>
      <g filter="url(#filter1_d_1384_16296)">
        <path
          d="M27.2686 33.2383L29.7886 54.2383C29.9092 55.2435 30.7619 56 31.7743 56H68.2257C69.2381 56 70.0908 55.2435 70.2114 54.2383L72.7314 33.2383C72.8743 32.0478 71.9447 31 70.7457 31H29.2543C28.0553 31 27.1257 32.0478 27.2686 33.2383Z"
          stroke="white"
          strokeWidth="4"
          shapeRendering="auto"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_1384_16296"
          x="28"
          y="16"
          width="48"
          height="21"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="2" dy="2" />
          <feGaussianBlur stdDeviation="1" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1384_16296" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1384_16296" result="shape" />
        </filter>
        <filter
          id="filter1_d_1384_16296"
          x="24.2538"
          y="26.5"
          width="51.4924"
          height="31.5"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="-1.5" />
          <feGaussianBlur stdDeviation="0.5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1384_16296" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1384_16296" result="shape" />
        </filter>
      </defs>
    </Icon>
  )
}
