import React from "react"
import { Icon, IconProps } from "@chakra-ui/react"

export function IconReturnArrow(props: IconProps) {
  return (
    <Icon width="54" height="54" viewBox="0 0 54 54" {...props}>
      <g filter="url(#filter0_d_1306_16167)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.8707 0.528619C14.2511 -0.683253 16.382 0.322637 16.382 2.18612V5.94467H28.6222C35.7967 5.94467 41.2028 8.6811 44.7886 12.8574C48.3565 17.0129 50.0604 22.5199 49.9984 27.985C49.9364 33.45 48.1082 38.9462 44.5182 43.0908C40.911 47.2555 35.5704 50 28.6222 50H2.12766C0.952584 50 0 49.0231 0 47.8181V39.4113C0 38.2063 0.952588 37.2294 2.12766 37.2294H28.6222C31.3982 37.2294 33.4145 36.1072 34.7548 34.4623C36.1154 32.7925 36.8237 30.5249 36.8237 28.213C36.8237 25.9011 36.1154 23.6334 34.7548 21.9637C33.4145 20.3188 31.3982 19.1966 28.6222 19.1966H16.382V22.4738C16.382 24.3373 14.2511 25.3432 12.8707 24.1313L1.31601 13.9875C0.324023 13.1166 0.324017 11.5433 1.31601 10.6725L12.8707 0.528619ZM14.2543 2.18612L2.69968 12.33L14.2543 22.4738L14.2543 17.0147H28.6222C32.0231 17.0147 34.6394 18.4193 36.3875 20.5646C38.1154 22.6852 38.9513 25.4712 38.9513 28.213C38.9513 30.9548 38.1154 33.7408 36.3875 35.8613C34.6394 38.0067 32.0231 39.4113 28.6222 39.4113H2.12766V47.8181H28.6222C34.978 47.8181 39.7343 45.3285 42.9275 41.6418C46.1381 37.9352 47.8141 32.9631 47.8709 27.9596C47.9276 22.9565 46.3649 17.9951 43.1917 14.2993C40.0364 10.6243 35.2268 8.12658 28.6222 8.12658H14.2543V2.18612Z"
          fill="white"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_1306_16167"
          x="0"
          y="0"
          width="54"
          height="54"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="2" dy="2" />
          <feGaussianBlur stdDeviation="1" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1306_16167" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1306_16167" result="shape" />
        </filter>
      </defs>
    </Icon>
  )
}
