import React from "react"
import { Icon, IconProps } from "@chakra-ui/react"

export function IconWindSet(props: IconProps) {
  return (
    <Icon viewBox="0 0 150 150" {...props}>
      <g filter="url(#filter0_d_31_2981)">
        <path
          d="M72.4074 20.9996V17.2498C72.4074 15.818 73.5682 14.6572 75 14.6572C76.4319 14.6572 77.5926 15.818 77.5926 17.2498V20.9996H72.4074Z"
          fill="white"
        />
        <path
          d="M129 72.4072V77.5924H132.75C134.182 77.5924 135.343 76.4316 135.343 74.9998C135.343 73.5679 134.182 72.4072 132.75 72.4072H129Z"
          fill="white"
        />
        <path
          d="M77.5926 128.991V132.75C77.5926 134.182 76.4319 135.343 75 135.343C73.5682 135.343 72.4074 134.182 72.4074 132.75V128.991H77.5926Z"
          fill="white"
        />
        <path
          d="M21.0094 77.5924V72.4072H17.2501C15.8182 72.4072 14.6575 73.5679 14.6575 74.9998C14.6575 76.4316 15.8182 77.5924 17.2501 77.5924H21.0094Z"
          fill="white"
        />
        <path
          d="M111.75 34.5836L114.084 32.25C115.096 31.2376 116.738 31.2376 117.75 32.25C118.762 33.2625 118.762 34.9041 117.75 35.9165L115.416 38.25L111.75 34.5836Z"
          fill="white"
        />
        <path
          d="M115.417 111.75L111.75 115.416L114 117.666C115.013 118.679 116.654 118.679 117.667 117.666C118.679 116.654 118.679 115.012 117.667 114L115.417 111.75Z"
          fill="white"
        />
        <path
          d="M38.2501 115.416L35.9164 117.75C34.9039 118.762 33.2624 118.762 32.2499 117.75C31.2374 116.738 31.2374 115.096 32.2499 114.084L34.5836 111.75L38.2501 115.416Z"
          fill="white"
        />
        <path
          d="M34.5839 38.25L38.2504 34.5835L36.0001 32.3333C34.9876 31.3208 33.3461 31.3208 32.3336 32.3333C31.3212 33.3457 31.3212 34.9873 32.3336 35.9997L34.5839 38.25Z"
          fill="white"
        />
      </g>
      <g filter="url(#filter1_d_31_2981)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M75 121.5C100.681 121.5 121.5 100.681 121.5 75C121.5 49.3188 100.681 28.5 75 28.5C49.3188 28.5 28.5 49.3188 28.5 75C28.5 100.681 49.3188 121.5 75 121.5ZM75 127.5C103.995 127.5 127.5 103.995 127.5 75C127.5 46.005 103.995 22.5 75 22.5C46.005 22.5 22.5 46.005 22.5 75C22.5 103.995 46.005 127.5 75 127.5Z"
          fill="white"
        />
      </g>
      <g filter="url(#filter2_d_31_2981)">
        <path
          d="M73.7871 57.5096C73.7871 57.5096 69.079 61.8864 66.7596 66.5841C65.1717 69.5538 64.3949 72.9103 64.5114 76.2976V91.5514C64.5389 91.8054 64.6245 92.0492 64.7611 92.2627C64.8977 92.4761 65.0815 92.6533 65.2975 92.7796C65.5135 92.906 65.7554 92.9779 66.0036 92.9895C66.2518 93.0011 66.4991 92.9521 66.7253 92.8465L74.3235 90.6016C74.7309 90.4435 75.1803 90.4435 75.5877 90.6016L83.2796 92.8573C83.5058 92.9621 83.753 93.0104 84.0008 92.9981C84.2486 92.9859 84.4901 92.9135 84.7055 92.7868C84.921 92.6602 85.1042 92.4829 85.2403 92.2694C85.3764 92.0559 85.4615 91.8123 85.4886 91.5586V76.2991C85.6051 72.9118 84.8283 69.5552 83.2404 66.5856C80.9217 61.8864 76.2129 57.5096 76.2129 57.5096C75.889 57.183 75.4535 57 75 57C74.5465 57 74.111 57.183 73.7871 57.5096Z"
          fill="white"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_31_2981"
          x="14.6575"
          y="14.6572"
          width="128.685"
          height="128.685"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="4" dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_31_2981" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_31_2981" result="shape" />
        </filter>
        <filter
          id="filter1_d_31_2981"
          x="22.5"
          y="22.5"
          width="113"
          height="113"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="4" dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_31_2981" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_31_2981" result="shape" />
        </filter>
        <filter
          id="filter2_d_31_2981"
          x="64.5"
          y="57"
          width="29"
          height="44"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="4" dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_31_2981" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_31_2981" result="shape" />
        </filter>
      </defs>
    </Icon>
  )
}
