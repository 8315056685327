import { Icon, IconProps } from "@chakra-ui/react"

export function IconIMO(props: IconProps) {
  return (
    <Icon viewBox="0 0 24 24" fill="none" {...props}>
      <text x="1.5" y="17.5" fill="inherit" fontSize="12px" fontWeight="bold">
        IMO
      </text>
    </Icon>
  )
}
