import { Icon, IconProps } from "@chakra-ui/react"

export function IconPreference(props: IconProps) {
  return (
    <Icon width="46" height="51" viewBox="0 0 46 51" fill="none" {...props}>
      <path
        d="M7.61011 14.6099C10.9238 14.6099 13.6101 11.9236 13.6101 8.60986C13.6101 5.29615 10.9238 2.60986 7.61011 2.60986C4.2964 2.60986 1.61011 5.29615 1.61011 8.60986C1.61011 11.9236 4.2964 14.6099 7.61011 14.6099Z"
        stroke="white"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M7 15V49" stroke="white" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M22.6101 36.6099C25.9238 36.6099 28.6101 33.9236 28.6101 30.6099C28.6101 27.2962 25.9238 24.6099 22.6101 24.6099C19.2964 24.6099 16.6101 27.2962 16.6101 30.6099C16.6101 33.9236 19.2964 36.6099 22.6101 36.6099Z"
        stroke="white"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M22 38V49" stroke="white" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M22 2V23" stroke="white" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M37.6101 21.6099C40.9238 21.6099 43.6101 18.9236 43.6101 15.6099C43.6101 12.2962 40.9238 9.60986 37.6101 9.60986C34.2964 9.60986 31.6101 12.2962 31.6101 15.6099C31.6101 18.9236 34.2964 21.6099 37.6101 21.6099Z"
        stroke="white"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M37 22V49" stroke="white" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M37 2V9" stroke="white" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
    </Icon>
  )
}
